import mock from '../mock'

const data = {
  // blog list
  catergoriesList: [
    {
      img: require('@src/assets/images/slider/02.jpg').default,
      title: 'Humor',
      slug: 'humor',
      id: 1,
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      userFullName: 'Ghani Pradita',
      blogPosted: 'Jan 10, 2020',
      tags: ['General', 'safe'],
      excerpt: '',
      comment: 76
    },
    {
      img: require('@src/assets/images/slider/06.jpg').default,
      title: 'Cars Hot',
      slug: 'cars-babes',
      id: 2,
      avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
      userFullName: 'Jorge Griffin',
      blogPosted: 'Jan 10, 2020',
      tags: ['Gaming', 'Video'],
      excerpt: '',
      comment: 2100
    },
    {
      img: require('@src/assets/images/slider/04.jpg').default,
      title: 'Blond',
      slug: 'blond',
      id: 3,
      avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
      userFullName: 'Claudia Neal',
      blogPosted: 'Jan 10, 2020',
      tags: ['Gaming', 'Food'],
      excerpt: '',
      comment: 243
    }
  ],
  sugestionList: [
    {
      img: require('@src/assets/images/slider/02.jpg').default,
      title: 'Loira',
      slug: 'loira',
      id: 1,
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      userFullName: 'Ghani Pradita',
      blogPosted: 'Jan 10, 2020',
      tags: ['General', 'safe'],
      excerpt: '',
      comment: 76
    },
    {
      img: 'https://i.ytimg.com/vi/z_LM3DLeiEY/hqdefault.jpg',
      title: 'only',
      slug: 'only',
      id: 1,
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      userFullName: 'Ghani Pradita',
      blogPosted: 'Jan 10, 2020',
      tags: ['General', 'safe'],
      excerpt: '',
      comment: 76
    },
    {
      img: require('@src/assets/images/slider/02.jpg').default,
      title: 'Babes',
      slug: 'babes',
      id: 1,
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      userFullName: 'Ghani Pradita',
      blogPosted: 'Jan 10, 2020',
      tags: ['General', 'safe'],
      excerpt: '',
      comment: 76
    }
  ]


}

mock.onGet('/tv/categories/data').reply(() => [200, data.catergoriesList])
mock.onGet('/tv/sugestions/data').reply(() => [200, data.sugestionList])
